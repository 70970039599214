import React from 'reactn';
import { Container, Row, Col } from 'reactstrap';

export default (props) => {
  return (
    <Container>
      <Row>
        <Col><h1>Login</h1></Col>
      </Row>
    </Container>
  );
};
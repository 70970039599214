import React, { useGlobal } from "reactn";
import { BrowserRouter, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

import Home from "./components/home/Home";
import Login from "./components/Login";
import Dashboard from "./components/private/Dashboard";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [user, setUser] = useGlobal(null);

  return (
    <div className="h-100">
      <BrowserRouter className="h-100">
        <div className="h-100">
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <PrivateRoute
            authenticated={user !== null}
            path="/dashboard"
            exact
            component={Dashboard}
          />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { getGlobal } from "reactn";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API;

class API {
  get(url) {
    return this.makeRequest("GET", url);
  }

  post(url, data = null) {
    return this.makeRequest("POST", url, data);
  }

  put(url, data = null) {
    return this.makeRequest("PUT", url, data);
  }

  delete(url) {
    return this.makeRequest("DELETE", url);
  }

  save(url, id, data) {
    const method = id === null || id === "new" ? "POST" : "PUT";

    url = method === "PUT" ? `${url}/${id}` : url;

    return this.makeRequest(method, url, data);
  }

  makeRequest(method, url, data = null) {
    const token = this.getUserToken();
    const config = {
      method,
      url,
    };

    if (data) {
      config.data = data;
    }

    if (token) {
      config.headers = {
        Authorization: "Bearer " + token,
      };
    }

    return axios(config);
  }

  getUserToken() {
    const token = getGlobal().token;

    if (!token) {
      return null;
    }

    return token;
  }
}

export default API;

import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { set } from "lodash";
import API from "../../lib/API";

const api = new API();

export default (props) => {
  const [data, setData] = useState({ name: "", email: "", message: "" });
  const [isSending, setIsSending] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [hasSent, setHasSent] = useState(false);

  const onDataChange = (e) => {
    const d = { ...data };
    set(d, e.target.id, e.target.value);
    setData(d);
  };

  const sendMessage = () => {
    if (isSending || !captchaToken) {
      return;
    }

    if (data.name === "" || data.email === "" || data.message === "") {
      return;
    }

    send();
    setIsSending(true);
  };

  const send = async () => {
    try {
      const payload = { ...data, recaptchaToken: captchaToken };
      const resp = await api.post("/contact", payload);

      console.log(resp);

      setHasSent(true);
    } catch (err) {
      alert(
        "An unexpected error was encountered while trying to send your message. Please try again."
      );
      setIsSending(false);
    }
  };

  const captchaOnChange = (value) => {
    setCaptchaToken(value);
  };

  const captchaOnError = (error) => {
    setCaptchaToken(null);
  };

  const captchaOnExpired = () => {
    setCaptchaToken(null);
  };

  const successMessage = () => {
    return (
      <Row>
        <Col>
          <Alert color="success">
            Your message has been successfully sent. Please give us up to 48
            hours to get back to you. We appreciate your time in contacting us!
          </Alert>
        </Col>
      </Row>
    );
  };

  if (hasSent) {
    return successMessage();
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage();
        return false;
      }}
    >
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <Label>Your Name</Label>
            <Input
              type="text"
              id="name"
              required={true}
              value={data.name}
              placeholder="John Doe"
              onChange={onDataChange}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6}>
          <FormGroup>
            <Label>Your Email</Label>
            <Input
              type="email"
              id="email"
              required={true}
              value={data.email}
              placeholder="johndoe@gmail.com"
              onChange={onDataChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Tell us about your idea or project.</Label>
            <Input
              type="textarea"
              id="message"
              required={true}
              value={data.message}
              placeholder="My wonderful idea..."
              onChange={onDataChange}
              style={{ minHeight: "150px" }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={captchaOnChange}
            onErrored={captchaOnError}
            onExpired={captchaOnExpired}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Button type="submit" color="primary">
            {isSending ? "Sending Message..." : "Send Message"}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

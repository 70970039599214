import React, { useRef } from "reactn";
import { Container, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagUsa, faGamepad, faGlobe, faMobile } from "@fortawesome/free-solid-svg-icons";
import Contact from "../common/Contact";
import "./Home.css";

// https://cannon-studio.s3.amazonaws.com/videos/es-loading-page-loop.mp4

export default (props) => {
  const whatWeDoRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop);
  }

  return (
    <>
      <header>
        <div id="home-nav">
          <Container>
            <Row>
              <Col xs={12} sm={3}><img src="images/Logo_Horizontal_Stacked_Solid.png" alt="Cannon Studio" title="Cannon Studio" id="logo" /></Col>
              <Col xs={12} sm={9}>
                <nav>
                  <Button type="button" color="link" onClick={() => scrollToRef(whatWeDoRef)}>What We Do</Button>
                  <Button type="button" color="link" onClick={() => scrollToRef(aboutUsRef)}>About Us</Button>
                  <Button type="button" color="link" onClick={() => scrollToRef(contactUsRef)}>Contact Us</Button>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div id="home-header">
            <Row>
              <Col>
                <div className="align-middle text-content">&nbsp;</div>
              </Col>
              <Col>&nbsp;</Col>
            </Row>
          </div>
        </Container>
      </header>
      <Container>
        <section>
          <h2 ref={whatWeDoRef} className="text-center">WHAT WE DO</h2>
          <p className="lead text-center mb-5">Cannon Studio provides many services for our clients.</p>
          <Row>
            <Col xs={12} sm={4} className="text-center">
              <h1 className="gray"><FontAwesomeIcon icon={faGlobe} /></h1>
              <h3 className="mb-3">Web Apps</h3>
              <p>With over 20 years of experience in web development, our team is capable of producing nearly and project - messaging, video chat, customer relation management, content management, etc.</p>
            </Col>
            <Col xs={12} sm={4} className="text-center">
              <h1 className="gray"><FontAwesomeIcon icon={faMobile} /></h1>
              <h3 className="mb-3">Mobile Apps</h3>
              <p>Out team is well-versed in the design and development of customer-facing or internal mobile applications on Android or iOS. We are also familiar with Progress Web Apps (PWA).</p>
            </Col>
            <Col xs={12} sm={4} className="text-center">
              <h1 className="gray"><FontAwesomeIcon icon={faGamepad} /></h1>
              <h3 className="mb-3">Games</h3>
              <p>Looking to create a new unique gaming experience for general consumption, team training or family entertainment? Cannon Studio can help you complete your project.</p>
            </Col>
          </Row>
        </section>
      </Container>

      <section style={{ backgroundColor: "rgba(140, 143, 147, 0.2)", padding: "2rem 0" }}>
        <Container>
          <Row>
            <Col xs={12} sm={6}>
              <img src="images/pexels-thisisengineering-3861972.jpg" title="Cannon Studio" alt="Cannon Studio" className="img-fluid" style={{ border: "1px solid #ccc", borderRadius: 15 }} />
            </Col>
            <Col xs={12} sm={6}>
              <h2>Also Covered</h2>
              <p className="lead mb-3">In addition to our core services, Cannon Studio also covers the following:</p>
              <ul>
                <li>Software-as-a-Service</li>
                <li>Infrastructure architecture and design</li>
                <li>HIPAA and HITRUST environments</li>
                <li>Big data and reporting</li>
                <li>Machine Learning and Artificial Intelligence</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <section className="text-center">
          <h2 className="mb-3">Projects We've Worked On</h2>
          <p className="lead mb-5">The following are a few the projects we've contributed to:</p>
          <Row>
            <Col xs={12} sm={6} className="mb-3">
              <h3>SMS Communication</h3>
              <p>SMS communication and fundraising is a leading means of collection donations for nonpofits and faith-based organizations. We have built several variations of SMS tools from standard keyword management to fundraising to AI-enabled survey tools.</p>
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <h3>Population Health</h3>
              <p>We developed the on-site, real-time screening software that empowers health screening companies to analyze and improve employee health much faster than traditional programs and means.</p>
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <h3>Customer Relationship Management</h3>
              <p>We have worked with several different companies to build out robust CRM solutions for a variety of industries. Such as post-acute care agencies; population health providers; and general sales pipelines.</p>
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <h3>Infrastructure Automation</h3>
              <p>Working with a major content management system provider, our team helped automate and streamline their media processing and encoding workloads. The new SaaS product converted a budget draining service into a new revenue stream.</p>
            </Col>
          </Row>
        </section>
      </Container>

      <section style={{ backgroundColor: "rgba(140, 143, 147, 0.2)", padding: "2rem 0" }}>
        <Container>
          <div className="text-center">
            <h2 ref={aboutUsRef}>About Us</h2>
            <p className="lead">Our team has over 20 years of development and design experience across a wide array of technologies and tools.</p>
          </div>
        </Container>
      </section>

      <Container>
        <section>
            <Row>
              <Col xs={12} sm={6}>
                <h2 ref={contactUsRef}>Get In Touch</h2>
                <p className="lead">We are always excited to hear about your ideas! Use the form to get the conversation started.</p>
              </Col>
              <Col xs={12} sm={6}>
                <Contact />
              </Col>
            </Row>
        </section>
      </Container>

      <footer>
        <Container>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <p>&copy; 2021 Cannon Studio, LLC</p>
              <p><FontAwesomeIcon icon={faFlagUsa} /> Veteran Owned and Operated</p>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <ul>
                <li>Web &amp; Desktop Apps</li>
                <li>Mobile Apps &middot; Android &middot; iOS</li>
                <li>Unity Games &middot; PC &middot; Mobile</li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <div className="footer-nav">
                <Button type="button" color="link" onClick={() => scrollToRef(whatWeDoRef)}>What We Do</Button>
                <Button type="button" color="link" onClick={() => scrollToRef(aboutUsRef)}>About Us</Button>
                <Button type="button" color="link" onClick={() => scrollToRef(contactUsRef)}>Contact Us</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
